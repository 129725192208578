import React from 'react';
import { string } from 'prop-types';
import { Button, Link } from '@chakra-ui/react';

import { GOOGLE_MAPS_LINK } from '@/constants';

function ViewLocationButton({ text, ...rest }) {
  return (
    <Link href={GOOGLE_MAPS_LINK} target="_blank">
      <Button {...rest}>{text}</Button>
    </Link>
  );
}

ViewLocationButton.propTypes = {
  text: string.isRequired,
};

export default ViewLocationButton;

import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
  DrawerHeader,
  Center,
  Box,
} from '@chakra-ui/react';

import LoveStorySwapable from '@components/Common/LoveStorySwipe';

import { BG_LOVESTORY } from '@constants/assets';

import txtWording from './locales';

function LoveStory({ onClose, visible, lang }) {
  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent
        maxW="500px"
        bgColor="bgPrimary"
        bgImage={`url(${BG_LOVESTORY})`}
        bgSize="cover"
      >
        <DrawerCloseButton marginTop="12px" display="none" />
        <DrawerHeader />
        <DrawerBody>
          <LoveStorySwapable />
          <Center>
            <Button
              margin="16px 0 32px 0"
              size="sm"
              letterSpacing="2px"
              textTransform="uppercase"
              bgColor="bgSecondary"
              onClick={onClose}
            >
              {txtWording.back[lang]}
            </Button>
          </Center>
          <Box height="50px" />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

LoveStory.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
};

LoveStory.defaultProps = {
  lang: 'id',
};

export default LoveStory;
import React from 'react';
import { string } from 'prop-types';
import { Box, Heading, Text, Divider } from '@chakra-ui/react';

import useGuestName from '@/hooks/useGuestName';
import useInvitation from '@hooks/useInvitation';
import WithAnimation from '@/components/Common/WithAnimation';

import { GIRL_NAME_SHORT, BOY_NAME_SHORT, IS_BOY_FIRST } from '@/constants';

import txt from './locales';

function WelcomeV4({ lang }) {
  const name = useGuestName('');
  const isInvitation = useInvitation();

  return (
    <Box padding="32px" bgColor="bgPrimary">
      <Box marginBottom="32px" color="mainColorText">
        <Text textAlign="center" fontWeight="light" fontFamily="serif">
          {txt.dear[lang]}
        </Text>
        <Text fontSize="xl" textAlign="center" fontFamily="serif" fontWeight="bold">
          {name ? name : `${txt.friend[lang]}`}
        </Text>
        <Text textAlign="center" margin="32px 0" color="mainColorText" padding="0 12px 0 12px">
          {isInvitation ? '' : txt.announcement[lang]}
        </Text>
      </Box>
      {/* Dear Section */}
      <Heading
        textColor="mainColorText"
        fontFamily="cursive"
        fontWeight="normal"
        fontSize="3xl"
        marginBottom="8px"
      >
        The Promises
      </Heading>
      <Box textColor="mainColorText" textTransform="uppercase" letterSpacing="4px">
        {IS_BOY_FIRST && (
          <>
            <Heading fontWeight="normal" fontSize="4xl">
              {BOY_NAME_SHORT}
            </Heading>
            <Heading fontWeight="normal" fontSize="4xl">
              {GIRL_NAME_SHORT}
            </Heading>
          </>
        )}
        {!IS_BOY_FIRST && (
          <>
            <Heading fontWeight="normal" fontSize="4xl">
              {GIRL_NAME_SHORT}
            </Heading>
            <Heading fontWeight="normal" fontSize="4xl">
              {BOY_NAME_SHORT}
            </Heading>
          </>
        )}
        <Divider
          marginTop="16px"
          width="110px"
          borderBottom="2px solid white"
          bgColor="mainColorText"
          opacity="1"
        />
      </Box>
      {/* Greetings Text */}
      <Box padding="36px 0 0 0">
        <WithAnimation>
          <Box>
            <Text color="mainColorText" fontFamily="serif">
              {txt.ayat[lang]}
            </Text>
            <Text color="mainColorText" fontFamily="serif" fontWeight="bold" marginTop="20px">
              {txt.ayatName[lang]}
            </Text>
          </Box>
        </WithAnimation>
      </Box>
    </Box>
  );
}

WelcomeV4.propTypes = {
  lang: string.isRequired,
};

export default WelcomeV4;
import { IMG_LOVE_STORY } from '@/constants/assets';

export default [
  {
    img: IMG_LOVE_STORY,
    title: 'Awal Berjumpa',
    content: `Anin dan Ariq memiliki dua karakter yang berbeda. Jika Anin cenderung lebih percaya diri, Ariq adalah sosok yang mulanya introvert.
    <br /><br />
    Mereka berdua adalah dua sejoli yang dipertemukan oleh Maha Kuasa di SMP 9 Semarang pada tahun 2012. Sejak awal melihat Anin, Ariq sudah kagum dengan Anin yg aktif dalam kegiatan sekolah. Menjadi sebuah kebetulan yang menguntungkan, mereka dipersatukan dalam satu kelas saat menaiki kelas IX. Tapi tetap saja, Anin belum mengetahui perasaan Ariq sesungguhnya. `,
  },
  {
    img: IMG_LOVE_STORY,
    title: 'Persahabatan',
    content: `Waktu terus berjalan, hingga tiba saatnya Anin dan Ariq menjadi lebih dekat sebagai sahabat. Rutinitas berangkat dan pulang sekolah bersama, selalu mereka jalankan bersama-sama. Tetapi, Ariq tetap tidak percaya diri jika kedekatannya ini diketahui oleh teman-teman satu sekolah. Hari demi hari mereka lalui hingga tidak terasa mereka sudah sama-sama lulus dan melanjutkan studi ke SMA yang berbeda-beda.`,
  },
  {
    img: IMG_LOVE_STORY,
    title: 'Pertemuan Kedua',
    content: `Setelah lulus, hubungan persahabatan Anin dan Ariq sempat renggang. Tapi jika yang Maha Kuasa sudah berkehendak, tidak ada yang bisa mengelak. Mereka berdua akhirnya dipertemukan lagi untuk pertama kalinya setelah sempat lost contact. 
    <br/> <br />
    Tidak mau menyia-nyiakan kesempatan lagi, momen ini dimanfaatkan oleh Ariq untuk menyatakan perasaannya pada Anin. Kedekatan yang terulang kembali, membuat mereka sama-sama semangat untuk meraih cita-cita. 
    <br/> <br />
    Anin dan Ariq kembali mengisi hari-hari bersama. Meski terpisah lokasi sekolah, namun mereka tergabung dalam satu bimbingan belajar dan bermimpi melanjutkan studi ke Politeknik Keuangan Negara STAN bersama.`,
  },
  {
    img: IMG_LOVE_STORY,
    title: 'Cita-cita',
    content: `Anin dan Ariq akhirnya sama-sama lolos dalam seleksi Politeknik Keuangan Negara STAN. Cita-cita sudah tercapai, namun perjalanan tetap tidak berhenti. Setelah lulus dari STAN, Anin mendapatkan penempatan di Jakarta Selatan dan Ariq mendapatkan penempatan di Sulawesi Selatan. Mereka berdua sepakat untuk menjalankan komitmen jarak jauh atau yang biasa disebut long distance relationship. `,
  },
  {
    img: IMG_LOVE_STORY,
    title: 'Keluarga',
    content: `Anin dan Ariq yang sudah bersama-sama sejak duduk di bangku SMP, kini akan memasuki jenjang yang lebih serius menjadi sebuah keluarga. Awal mula cerita hingga memasuki jenjang ini, keluarga Ariq mendatangi rumah Anin untuk menyatakan niat serius. Tidak ada Ariq dan Anin. Hanya keluarga Ariq yang menemui keluarga Anin. Setelah pertemuan itu, semua berjalan begitu lancar. Kedua belah pihak sepakat melanjutkan hubungan ke arah pernikahan.`,
  },
];

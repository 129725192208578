import React from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Text, Image } from '@chakra-ui/react';

import story from './lovestory-data';

// Import Swiper styles - MANDATORY
import 'swiper/css';
import 'swiper/css/pagination';

import './styles.css';

SwiperCore.use([Pagination]);

function LoveStorySwapable() {
  return (
    <Swiper
      pagination={{
        dynamicBullets: true,
      }}
      autoHeight
      className="mySwiper"
    >
      {story.map((i) => {
        return (
          <SwiperSlide key={i.title}>
            <Box padding="16px" borderRadius="16px" bgColor="#6C6C6C" cursor="pointer">
              <Image src={i.img} maxWidth="100%" />
              <Text
                marginTop="8px"
                marginLeft="4px"
                color="mainColorText"
                fontFamily="fantasy"
                fontSize="2xl"
              >
                {i.title}
              </Text>
              <Text color="mainColorText" dangerouslySetInnerHTML={{ __html: i.content }} />
            </Box>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default LoveStorySwapable;

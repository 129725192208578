import React from 'react';
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Image,
  Center,
  DrawerBody,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { func, bool } from 'prop-types';

import { IMG_COVID } from '@/constants/assets';
import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags'

function WeddingProtocol({ visible, onClose }) {
  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody>
          <Center>
            <Image src={IMG_COVID[lang]} width="100%" maxWidth="500px" marginBottom="24px" />
          </Center>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

WeddingProtocol.propTypes = {
  visible: bool.isRequired,
  onClose: func.isRequired,
};

export default WeddingProtocol;
